<template>
  <i :class="iconClass"></i>
</template>
<script>
export default {
  name: "icon",
  props: {
    type: {
      type: String,
      required: true,
    },
  },
  computed: {
    iconClass: function () {
      return `fas fa-${this.type} fa-fw`;
    },
  },
};
</script>
