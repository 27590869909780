<template>
  <div>
    <div :class="styles.FORM.FORM_GROUP">
      <label> Canvas Width </label>
      <input
        type="number"
        :class="styles.FORM.FORM_CONTROL"
        v-model="control.canvasWidth"
      />
    </div>

    <div :class="styles.FORM.FORM_GROUP">
      <label> Canvas Height </label>
      <input
        type="number"
        :class="styles.FORM.FORM_CONTROL"
        v-model="control.canvasHeight"
      />
    </div>
    <div :class="styles.FORM.FORM_GROUP">
      <label> Image Url </label>
      <input
        type="text"
        :class="styles.FORM.FORM_CONTROL"
        v-model="control.imageUrl"
      />
    </div>
    <div :class="styles.FORM.FORM_GROUP">
      <label> Image Position </label>
      <select :class="styles.FORM.FORM_CONTROL" v-model="control.imagePosition">
        <option value="left">Left</option>
        <option value="center">Center</option>
        <option value="right">Right</option>
      </select>
    </div>
    <div :class="styles.FORM.FORM_GROUP">
      <label>
        Image Border?
        <input type="checkbox" v-model="control.hasImageBorder" />
      </label>
    </div>
    <div :class="styles.FORM.FORM_GROUP">
      <label> Default Drawing Tool </label>
      <select :class="styles.FORM.FORM_CONTROL" v-model="control.defaultTool">
        <option value="">None</option>
        <option value="freeDrawing">Free Drawing</option>
        <option value="text">Add Text</option>
        <option value="circle">Draw Circle</option>
        <option value="rect">Draw Rectangle</option>
        <option value="arrow">Draw Arrow</option>
      </select>
    </div>
    <div :class="styles.FORM.FORM_GROUP">
      <label> Default Color (Hex or Name) </label>
      <input
        type="text"
        :class="styles.FORM.FORM_CONTROL"
        placeholder="#ff0000 or red"
        v-model="control.defaultColor"
      />
    </div>
  </div>
</template>

<script>
import { BaseControlConfigSkeleton } from "@ray-solutions/v-form-builder";
export default {
  name: "ImageMarkupConfigView",
  extends: BaseControlConfigSkeleton,
};
</script>
