<template>
  <div class="links-container">
    <a
      v-for="(link, index) in LinkNamesList"
      :key="`${link + index}`"
      :download="`${link}.pdf`"
      :href="`${publicPath + link}.pdf`"
      rel="PDF"
    >
      {{ link }}
    </a>
  </div>
</template>

<script>
export default {
  name: "PDFDownloaderGroup",
  props: {
    LinkNamesList: {
      type: Array,
    },
  },
  data() {
    return {
      publicPath: process.env.BASE_URL,
    };
  },
};
</script>

<style scoped>
.links-container {
  display: flex;
  align-items: baseline;
  justify-content: center;
}
.links-container a {
  color: #99badc;
  max-width: 25%;
  margin: 0px 15px;
}
.links-container a:hover {
  color: #538ce5;
}
</style>
