<template>
  <div class="card shadow-card transparent-card border-0">
    <div id="map" class="map"></div>
    <div v-if="showMapLegend" id="legend" class="legend">
      <div class="title">Map Legend</div>
      <div v-for="type in icons" class="legend-item" :key="type.name">
        <img
          v-bind:src="
            type.icon ||
            `http://maps.google.com/mapfiles/ms/icons/${type.iconColor}-dot.png`
          "
        />
        {{ type.name }}
      </div>
    </div>
  </div>
</template>
<script>
import gmapsInit from "@/utils/gmaps";

let google = null;
let map = null;
let infowindow = null;

export default {
  name: "GoogleMap",
  props: {
    currentLocation: {
      type: Object,
      default: null,
    },
    defaultZoom: {
      type: Number,
      default: 11,
    },
    showMapLegend: {
      type: Boolean,
      default: false,
    },
    icons: {
      type: Array,
      default: null,
    },
    markers: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      closeZoom: 12,
      google: null,
      map: null,
      coder: null,
      latLng: false,
      currentPin: require("@/assets/img/icons/current-location.gif"),
    };
  },
  async mounted() {
    try {
      google = await gmapsInit();
      if (document.getElementById("map")) {
        map = await new google.maps.Map(document.getElementById("map"), {
          zoom: this.defaultZoom,
        });
        // Create info window
        infowindow = new google.maps.InfoWindow({
          maxWidth: 350,
          pixelOffset: new google.maps.Size(-10, -25),
        });
        this.createMapLegend();
        this.render();
        await this.geocode();
        this.plotMarkers(this.markers);
      }
    } catch (error) {
      //eslint-disable-next-line
      console.log(error);
    }
  },
  methods: {
    render: function () {
      map.setOptions({
        maxZoom: 16,
        minZoom: 1,
        disableDefaultUI: true,
        mapTypeId: google.maps.MapTypeId.ROADMAP,
      });
    },
    geocode: async function () {
      if (this.currentLocation) {
        map.setCenter({
          lat: parseFloat(this.currentLocation.lat),
          lng: parseFloat(this.currentLocation.lng),
        });
      } else if (this.markers && this.markers.length) {
        map.setCenter({
          lat: parseFloat(this.markers[0].lat), // view my location
          lng: parseFloat(this.markers[0].lng),
        });
      } else {
        // set map center to Ottawa (Capital of CAN)
        map.setCenter({
          lat: 45.4215296,
          lng: -75.69719309999999,
        });
      }
    },
    plotMarkers: function () {
      if (this.markers && this.markers.length) {
        this.markers.forEach((marker) => {
          const latLng = {
            lat: parseFloat(marker.lat),
            lng: parseFloat(marker.lng),
          };

          const markerColor = marker.markerColor || "red";

          var gmarker = new google.maps.Marker({
            map: map,
            position: latLng,
            icon: marker.icon || {
              url: `http://maps.google.com/mapfiles/ms/icons/${markerColor}-dot.png`,
            },
            metadata: marker,
          });
          google.maps.event.addListener(gmarker, "click", () => {
            infowindow.setContent(marker.content);
            infowindow.open(map);
            infowindow.setPosition(
              new google.maps.LatLng(marker.lat, marker.lng)
            );
          });
        });
      }
      if (this.currentLocation) {
        new google.maps.Marker({
          map: map,
          icon: new google.maps.MarkerImage(
            this.currentPin,
            null,
            null,
            null,
            new google.maps.Size(24, 37.5)
          ),
          position: this.currentLocation,
        });
      }
    },
    createMapLegend: function () {
      if (this.showMapLegend && this.icons && this.icons.length) {
        const legend = document.getElementById("legend");
        map.controls[google.maps.ControlPosition.RIGHT_CENTER].push(legend);
      }
    },
  },
};
</script>
<style lang="scss">
.transparent-card {
  background-color: transparent;
}
.map {
  height: 600px !important;
  background-color: transparent;
  border-radius: 36px;
}

.map + .legend {
  display: none;
}

#legend {
  background-color: rgba(255, 255, 255, 0.9);
  padding: 10px;
  margin: 10px;
  border: 3px solid var(--theme-color);
  border-radius: 36px;
  font-weight: bold;

  @media (max-width: 767px) {
    display: none;
  }
}
.legend {
  .title {
    padding: 5px 0px;
    border-bottom: 1px solid #000;
    font-size: 18px;
  }
  .legend-item {
    padding: 10px 5px;
    font-size: 15px;
  }
  img {
    vertical-align: middle;
  }
}
</style>
