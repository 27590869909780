<template>
  <li class="nav-item dropdown">
    <a href="#" class="nav-link dropdown-toggle u-mr-8" data-toggle="dropdown"
      >My Profile</a
    >
    <div class="dropdown-menu">
      <h6 class="dropdown-header text-blue">
        {{ user.last }}, {{ user.first }}
      </h6>
      <a
        href="/"
        class="dropdown-item"
        @click.prevent="navigateToProfile"
        data-toggle="collapse"
        data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        >Profile Update</a
      >
      <div class="dropdown-divider"></div>
      <a
        href="#"
        @click.prevent="handleLogout()"
        class="dropdown-item"
        data-toggle="collapse"
        data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        >Logout</a
      >
    </div>
  </li>
</template>
<script>
import { mapState, mapActions } from "vuex";
export default {
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
  },
  methods: {
    ...mapActions({
      doLogout: "auth/logout",
    }),
    handleLogout: function () {
      this.doLogout().then((res) => {
        if (res) {
          this.$router.push({ path: "/login" });
          window.location.href = `${window.location.origin}`;
        }
      });
    },
    navigateToProfile: function () {
      if (this.user) {
        if (this.user.isAdmin || this.user.isManagingAdmin) {
          this.$router.push({ path: "/admin/profile" });
        } else if (this.user.isProvider) {
          this.navigateToProviderDetails();
        } else if (this.user.isPatient) {
          this.navigateToPatientDetails();
        }
      }
    },
    navigateToProviderDetails: function () {
      this.$router.push({ path: `/providers/${this.user.provider_id}` });
    },
    navigateToPatientDetails: function () {
      this.$router.push({ path: `/clients/${this.user.patient_id}` });
    },
  },
};
</script>
