<template>
  <div class="form-outer-group">
    <div class="form-group" :class="`${styleClass.parentElement} ${wrapClass}`">
      <label :class="`${styleClass.label} ${labelClass}`" :for="inputId">
        <slot>Label</slot>
      </label>
      <div :class="`${styleClass.input} ${inputClass}`">
        <input
          :type="control"
          class="form-control"
          :id="inputId"
          :value="value"
          :step="control == 'number' && step"
          :min="control == 'number' && min"
          autocomplete="off"
          :required="required"
          :placeholder="placeholder"
          :disabled="disabled"
          :autofocus="autofocus"
          :maxlength="maxLength"
          @input="update($event.target.value)"
          v-cleave="cleave"
          v-if="
            [
              'text',
              'password',
              'radio',
              'checkbox',
              'email',
              'number',
            ].includes(control)
          "
        />

        <textarea
          class="form-control"
          :id="inputId"
          :value="value"
          v-if="control == 'textarea'"
          autocomplete="off"
          :required="required"
          :placeholder="placeholder"
          :autofocus="autofocus"
          :maxlength="maxLength"
          :disabled="disabled"
          :readonly="readonly"
          @input="update($event.target.value)"
          v-autosize
        ></textarea>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "InputControl",
  props: {
    value: [String, Number, Boolean],
    type: {
      type: String,
      default: "stacked",
    },
    placeholder: {
      type: String,
      default: "",
    },
    required: {
      type: Boolean,
      default: false,
    },
    autofocus: {
      type: Boolean,
      default: false,
    },
    control: {
      type: String,
      default: "text",
    },
    step: String,
    min: String,
    cleave: {
      type: Object,
      default: null,
    },
    maxLength: String,
    disabled: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    wrapClass: {
      type: String,
      default: "",
    },
    labelClass: {
      type: String,
      default: "",
    },
    inputClass: {
      type: String,
      default: "",
    },
  },
  computed: {
    inputId: function () {
      let id = this.$slots.default[0].text
        .toLowerCase()
        .replace(/[^a-z0-9]+/gi, "-");
      return `input-control-${id}`;
    },
  },
  data() {
    return {
      styleClass: {
        parentElement: "",
        label: "",
        input: "",
      },
      fieldType: "text",
    };
  },
  mounted() {
    if (this.type == "horizontal") {
      this.styleClass.parentElement = "row";
      this.styleClass.label = "col-md-2 col-form-label";
      this.styleClass.input = "col";
    }
  },

  methods: {
    update: function (value) {
      this.$emit("input", value);
    },
  },
};
</script>
