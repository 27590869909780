var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-outer-group"},[_c('div',{staticClass:"form-group",class:`${_vm.styleClass.parentElement} ${_vm.wrapClass}`},[_c('label',{class:`${_vm.styleClass.label} ${_vm.labelClass}`,attrs:{"for":_vm.inputId}},[_vm._t("default",function(){return [_vm._v("Label")]})],2),_c('div',{class:`${_vm.styleClass.input} ${_vm.inputClass}`},[(
          [
            'text',
            'password',
            'radio',
            'checkbox',
            'email',
            'number',
          ].includes(_vm.control)
        )?_c('input',{directives:[{name:"cleave",rawName:"v-cleave",value:(_vm.cleave),expression:"cleave"}],staticClass:"form-control",attrs:{"type":_vm.control,"id":_vm.inputId,"step":_vm.control == 'number' && _vm.step,"min":_vm.control == 'number' && _vm.min,"autocomplete":"off","required":_vm.required,"placeholder":_vm.placeholder,"disabled":_vm.disabled,"autofocus":_vm.autofocus,"maxlength":_vm.maxLength},domProps:{"value":_vm.value},on:{"input":function($event){return _vm.update($event.target.value)}}}):_vm._e(),(_vm.control == 'textarea')?_c('textarea',{directives:[{name:"autosize",rawName:"v-autosize"}],staticClass:"form-control",attrs:{"id":_vm.inputId,"autocomplete":"off","required":_vm.required,"placeholder":_vm.placeholder,"autofocus":_vm.autofocus,"maxlength":_vm.maxLength,"disabled":_vm.disabled,"readonly":_vm.readonly},domProps:{"value":_vm.value},on:{"input":function($event){return _vm.update($event.target.value)}}}):_vm._e()])])])
}
var staticRenderFns = []

export { render, staticRenderFns }