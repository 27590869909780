<template>
  <modal name="file-viewer-modal" class="file-viewer-modal" :focus-trap="true">
    <div class="file-viewer-modal-container">
      <div class="modal-title border-bottom text-secondary position-relative">
        <p class="p-2 m-0 file-name text-secondary">{{ name || "" }}</p>
        <button class="close mr-3 pr-2" @click="closeModal">&times;</button>
      </div>
      <div
        class="content-container"
        :class="`${
          type == 'image' ? 'image-content-container' : 'border-bottom'
        }`"
      >
        <div class="image-view-container" v-if="type == 'image'">
          <div class="zoom-actions">
            <i
              class="fas fa-lg fa-search-minus mr-2"
              @click="zoom(-10)"
              :class="`${zoomLevel == 10 ? 'disabled' : ''}`"
            ></i>
            <i
              class="fas fa-lg fa-search-plus"
              @click="zoom(10)"
              :class="`${zoomLevel == 160 ? 'disabled' : ''}`"
            ></i>
          </div>
          <img
            :src="parsedLink"
            class="file-image"
            :style="`width: ${zoomLevel}%;`"
          />
        </div>
        <div class="document-view-container" v-if="type == 'pdf'">
          <iframe
            :src="`https://drive.google.com/viewer?url=${parsedLink}&embedded=true`"
          ></iframe>
        </div>
        <div
          class="document-view-container"
          v-if="type !== 'image' && type !== 'pdf'"
        >
          <iframe
            :src="`https://view.officeapps.live.com/op/view.aspx?src=${parsedLink}`"
          ></iframe>
        </div>
      </div>
      <div
        class="text-center"
        :class="`${type == 'image' ? 'image-close' : 'u-display-none'}`"
      >
        <button
          class="btn btn-outline-blue"
          :class="`${type == 'image' ? 'btn-sm' : ''}`"
          @click="closeModal"
        >
          Close
        </button>
      </div>
    </div>
  </modal>
</template>

<script>
export default {
  name: "FileViewerModal",
  props: {
    type: {
      type: String,
      required: true,
    },
    link: {
      type: String,
      required: true,
    },
    name: String,
  },
  data() {
    return {
      zoomLevel: 100,
    };
  },
  computed: {
    parsedLink: function () {
      if (this.type != "image") {
        return encodeURIComponent(this.link);
      }
      return this.link;
    },
  },
  methods: {
    closeModal: function () {
      this.$modal.hide("file-viewer-modal");
    },
    zoom: function (value) {
      if (
        (this.zoomLevel == 10 && value < 0) ||
        (this.zoomLevel == 160 && value > 0)
      ) {
        return;
      }
      this.zoomLevel = this.zoomLevel + value;
    },
  },
};
</script>

<style lang="scss">
.file-viewer-modal {
  .v--modal-box {
    width: 90% !important;
    height: 96vh !important;
    top: 2vh !important;
    left: 5% !important;
  }
}
.file-viewer-modal-container {
  height: 100%;

  .file-name {
    display: inline-block;
    max-width: 90%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 14px;
    padding-right: 20px !important;
  }

  .content-container {
    overflow-y: auto;
    overflow-x: auto;
    height: calc(100% - 34px);
    position: relative;

    .document-view-container {
      iframe {
        border: 0;
        position: absolute;
        width: 100%;
        height: 100%;
      }
    }

    .image-view-container {
      text-align: center;
      .zoom-actions {
        z-index: 9;
        position: fixed;
        top: calc(2vh + 60px);
        right: calc(5% + 20px);
        i {
          color: #062157;
          padding: 8px 4px;
          background-color: rgba($color: #ffffff, $alpha: 0.5);
          transition: all 0.3s ease-in-out;
          box-shadow: 0px 0px 6px 0px #062157;
          cursor: pointer;
          border-radius: 6px;
          &:hover {
            background-color: rgba($color: #ffffff, $alpha: 0.8);
          }
          &.disabled {
            opacity: 0.3;
            cursor: default;
            &:hover {
              background-color: rgba($color: #ffffff, $alpha: 0.5);
            }
          }
        }
      }
      .file-image {
        max-width: 160%;
        transition: all 0.3s ease-in-out;
      }
    }
  }
  .image-close {
    position: absolute;
    bottom: 11px;
    left: 0;
    right: 0;
    padding: 8px;
    background: rgba($color: #ffffff, $alpha: 0.3);
  }
  .modal-title {
    text-align: center;
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: center;

    .close {
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      font-size: 35px;
    }
  }
}
</style>
