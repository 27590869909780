<template>
  <nav :class="mutableToggle ? 'collapseDrawer' : ''">
    <span class="nav-toggler-icon" @click="toggleSideBar">
      <i
        :class="
          mutableToggle
            ? 'fas fa-arrow-alt-circle-left rotate'
            : 'fas fa-arrow-alt-circle-left'
        "
      ></i>
    </span>
    <div>
      <div class="d-flex justify-content-center mb-3 mt-5 section-title">
        <h2>{{ header }}</h2>
      </div>
      <main>
        <slot name="body" :toggle="toggleSideBar"></slot>
      </main>
    </div>
    <footer>
      <slot name="footer"></slot>
    </footer>
  </nav>
</template>

<script>
export default {
  props: {
    toggle: {
      type: Boolean,
      default: true,
    },
    header: String,
  },
  data() {
    return {
      mutableToggle: JSON.parse(this.toggle),
    };
  },
  watch: {
    mutableToggle() {
      this.$emit("getToggle", this.mutableToggle);
    },
  },
  mounted() {
    this.$emit("getToggle", this.mutableToggle);
  },
  methods: {
    toggleSideBar: function () {
      this.mutableToggle = !this.mutableToggle;
    },
  },
};
</script>

<style scoped lang="scss">
nav {
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: fixed;
  height: 100%;
  min-width: 235px;
  width: 18%;
  top: 110px;
  left: 0;
  z-index: 1;
  padding: 10px;
  -webkit-box-shadow: inset -0.5px 0 0 0 rgb(0 0 0 / 25%);
  box-shadow: inset -0.5px 0 0 0 rgb(0 0 0 / 25%);
  background-color: #143370fa;
  transition: 0.3s;
}
.nav-toggler-icon {
  position: absolute;
  border-radius: 50%;
  background-color: #e7efff;
  max-width: 24px;
  max-height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  right: -12px;
  top: 10px;
  cursor: pointer;
  box-shadow: rgb(9 30 66 / 8%) 0px 0px 0px 1px,
    rgb(9 30 66 / 8%) 0px 2px 4px 1px;
  > i {
    font-size: 27px;
    color: #143370;
  }
}
.nav-toggler-icon img {
  min-width: 36px;
}
.section-title {
  font-size: 20px;
  font-weight: 500;
  text-align: center;
  color: #ffffff;
}
.collapseDrawer {
  transform: translateX(-94%);
  transition: 0.3s;
  background: transparent;
}
.rotate {
  transform: rotate(180deg);
  transition: 0.3s;
}
</style>
