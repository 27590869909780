<template>
  <div class="markup_container" :id="`${control.uniqueId}_container`">
    <input
      type="hidden"
      :name="control.name || control.uniqueId"
      :value="value"
      @input="updateValue($event.target.value)"
      :id="control.uniqueId"
    />
    <div class="editor-container">
      <div class="editor noPrint">
        <div
          class="tool current-color"
          :style="{ backgroundColor: color }"
        ></div>
        <div class="tool undo" @click="undo">
          <i class="fas fa-undo-alt fa-lg"></i>
        </div>
        <div class="tool redo" @click="redo">
          <i class="fas fa-redo-alt fa-lg"></i>
        </div>
        <div class="tool clear" @click="clear">
          <i class="fas fa-trash-alt fa-lg"></i>
        </div>
        <div
          class="tool free-drawing"
          @click="setTool('freeDrawing')"
          :class="{ 'active-tool': currentActiveMethod === 'freeDrawing' }"
        >
          <i class="fas fa-pencil-alt fa-lg"></i>
        </div>
        <div
          class="tool add-text"
          @click="setTool('text')"
          :class="{ 'active-tool': currentActiveMethod === 'text' }"
        >
          <i class="fas fa-font fa-lg"></i>
        </div>
        <div
          class="tool custom-circle"
          @click="setTool('circle')"
          :class="{ 'active-tool': currentActiveMethod === 'circle' }"
        >
          <i class="far fa-circle fa-lg"></i>
        </div>
        <div
          class="tool custom-custom-rect"
          @click="setTool('rect')"
          :class="{ 'active-tool': currentActiveMethod === 'rect' }"
        >
          <i class="far fa-square fa-lg"></i>
        </div>
        <div
          class="tool arrow"
          @click="setTool('arrow')"
          :class="{ 'active-tool': currentActiveMethod === 'arrow' }"
        >
          <i class="fas fa-long-arrow-alt-down fa-lg"></i>
        </div>
        <div class="tool upload-image">
          <label :for="`${control.uniqueId}_chooseImage`" class="mb-0 d-flex"
            ><i class="fas fa-file-upload fa-lg"></i
          ></label>
          <input
            :id="`${control.uniqueId}_chooseImage`"
            style="visibility: hidden"
            type="file"
            @change="uploadImage"
            accept="image/*"
          />
        </div>
        <div class="tool save-image" @click="downloadImage">
          <i class="fas fa-download fa-lg"></i>
        </div>
        <div class="tool save-image" title="Apply Changes" @click="saveImage">
          <i
            v-if="!applying"
            class="fas fa-save fa-lg pointer-cursor text-primary"
          ></i>
          <i v-if="applying" class="fas fa-check-circle fa-lg text-success"></i>
        </div>
      </div>
      <Editor
        :key="`${control.uniqueId}_${canvasWidth}_${canvasHeight}`"
        :editorId="`${control.uniqueId}_${canvasWidth}_${canvasHeight}`"
        :canvasWidth="canvasWidth"
        :canvasHeight="canvasHeight"
        :class="[
          control.hasImageBorder ? 'image-border' : '',
          `image-${control.imagePosition}`,
        ]"
        ref="editor"
      />
    </div>
    <div class="colors noPrint">
      <div class="color-container yellow" @click="changeColor('#fefe30')"></div>
      <div
        class="color-container yellow-camon"
        @click="changeColor('#e8eb34')"
      ></div>
      <div class="color-container red" @click="changeColor('#e40000')"></div>
      <div
        class="color-container orange-light"
        @click="changeColor('#FABD00')"
      ></div>
      <div class="color-container orange" @click="changeColor('#FB9A00')"></div>
      <div class="color-container green" @click="changeColor('#008000')"></div>
      <div
        class="color-container green-light"
        @click="changeColor('#66B22E')"
      ></div>
      <div class="color-container purple" @click="changeColor('#a834eb')"></div>
      <div
        class="color-container pink-dark"
        @click="changeColor('#A8124A')"
      ></div>
      <div
        class="color-container blue-light"
        @click="changeColor('#34b7eb')"
      ></div>
      <div class="color-container blue" @click="changeColor('#1a10ad')"></div>
      <div class="color-container black" @click="changeColor('#000000')"></div>
    </div>
    <div class="save-hint pointer-cursor noPrint" @click="saveImage">
      {{ applying ? "Changes applied" : "Apply changes" }}
      <i v-if="!applying" class="fas fa-save fa-lg text-primary"></i>
      <i v-if="applying" class="fas fa-check-circle fa-lg text-success"></i>
    </div>
  </div>
</template>

<script>
import { BaseControlSkeleton } from "@ray-solutions/v-form-builder";
import Editor from "vue-image-markup";
export default {
  name: "ImageMarkupControl",
  extends: BaseControlSkeleton,
  components: {
    Editor,
  },
  data() {
    return {
      currentActiveMethod: null,
      canvasWidth: this.control.canvasWidth,
      canvasHeight: this.control.canvasHeight,
      uploadedImage: "",
      params: {},
      applying: false,
      color: "black",
      defaultTool: this.control.defaultTool,
      defaultColor: this.control.defaultColor,
      imageUrl: this.control.imageUrl || this.control.defaultValue,
      croppedImage: false,
    };
  },
  watch: {
    /**
     * Watcher for Configuration - FormBuilder Mode
     * @param val
     */
    value() {
      setTimeout(() => {
        this.mountImage();
      }, 10);
    },
    "control.canvasWidth": function (val) {
      this.canvasWidth = parseInt(val);
      setTimeout(() => {
        this.mountImage();
      }, 10);
    },
    "control.canvasHeight": function (val) {
      this.canvasHeight = parseInt(val);
      setTimeout(() => {
        this.mountImage();
      }, 10);
    },
    "control.defaultValue": function (val) {
      this.imageUrl = val.trim();
      setTimeout(() => {
        this.mountImage();
      }, 10);
    },
    "control.imageUrl": function (val) {
      this.imageUrl = val.trim();
      setTimeout(() => {
        this.mountImage();
      }, 10);
    },
    "control.defaultTool": function (val) {
      setTimeout(() => {
        this.setTool(val.trim());
      }, 10);
    },
    "control.defaultColor": function (color) {
      setTimeout(() => {
        this.changeColor(color.trim());
      }, 10);
    },
  },
  mounted() {
    this.canvasWidth = this.control.canvasWidth;
    this.canvasHeight = this.control.canvasHeight;
    this.defaultTool = this.control.defaultTool;
    this.defaultColor = this.control.defaultColor;
    setTimeout(() => {
      if (this.defaultTool) {
        this.setTool(this.defaultTool.trim());
      }
      if (this.defaultColor) {
        this.changeColor(this.defaultColor.trim());
      }
      this.mountImage();
    }, 10);
    this.$watch(
      () => {
        return this.$refs.editor.croppedImage;
      },
      (val) => {
        this.croppedImage = val;
      }
    );
  },
  methods: {
    mountImage() {
      this.imageUrl =
        this.value ||
        this.imageUrl ||
        this.control.imageUrl ||
        this.defaultValue;
      if (this.imageUrl) {
        this.$refs.editor.setBackgroundImage(this.imageUrl);
        this.uploadedImage = this.imageUrl;
        this.croppedImage = this.$refs.editor.croppedImage;
      } else if (this.uploadedImage) {
        this.uploadImage(this.uploadedImage);
      }
    },
    changeColor(colorHex) {
      this.color = colorHex;
      this.$refs.editor.changeColor(colorHex);
    },
    downloadImage() {
      const image = this.$refs.editor.saveImage();
      this.saveImageAsFile(image);
    },
    saveImage() {
      this.applying = true;
      setTimeout(() => {
        this.applying = false;
      }, 2000);
      // apply image to form builder.
      const image = this.$refs.editor.saveImage();
      this.imageUrl = image;
      this.control.imageUrl = image;
      this.updateValue(image);
    },
    saveImageAsFile(base64) {
      const link = document.createElement("a");
      link.setAttribute("href", base64);
      link.setAttribute("download", "image-markup");
      link.click();
    },
    setTool(type, params) {
      this.currentActiveMethod = type;
      if (type == "crop-apply") {
        this.$refs.editor.applyCropping();
      } else {
        this.$refs.editor.set(type, params);
      }
    },
    uploadImage(e) {
      this.$refs.editor.uploadImage(e);
      this.uploadedImage = e;
    },
    clear() {
      this.currentActiveMethod = this.clear;
      this.$refs.editor.clear();
      this.control.imageUrl = "";
      this.updateValue("");
    },
    undo() {
      this.currentActiveMethod = this.undo;
      this.$refs.editor.undo();
    },
    redo() {
      this.currentActiveMethod = this.redo;
      this.$refs.editor.redo();
    },
  },
};
</script>

<style scoped lang="scss">
.markup_container {
  position: relative;
  padding-bottom: 26px;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 100%;
  max-width: 100%;
}
.markup_container,
.editor {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.editor {
  visibility: hidden;
  height: 0;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  align-content: flex-end;
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
  overflow: auto;
}
.markup_container:hover {
  .editor,
  .colors,
  .save-hint {
    height: auto;
    visibility: visible;
  }
  .editor {
    display: flex !important;
  }
}
.colors {
  height: 0;
  visibility: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  margin: 40px 0 0 -25px;
  z-index: 1;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;

  .color-container {
    border-radius: 50%;
    width: 30px;
    min-width: 30px;
    height: 30px;
    min-height: 30px;
    margin: 5px 0;
    cursor: pointer;
    transition: transform 0.2s ease;
    &:hover {
      transform: scale(1.2);
    }

    &.red {
      background-color: #e40000;
    }
    &.yellow-camon {
      background-color: #e8eb34;
    }
    &.yellow {
      background-color: yellow;
    }
    &.orange-light {
      background-color: #fabd00;
    }
    &.orange {
      background-color: #fb9a00;
    }
    &.purple {
      background-color: #a834eb;
    }
    &.green {
      background-color: #008000;
    }
    &.green-light {
      background-color: #66b22e;
    }
    &.blue-light {
      background-color: #34b7eb;
    }
    &.pink-dark {
      background-color: #a8124a;
    }
    &.blue {
      background-color: #1a10ad;
    }
    &.black {
      background-color: #000;
    }
  }
}
.editor-container,
.tool {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.editor-container {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}
.tool {
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  font-size: 16px;
  padding: 2px 5px;
  margin-right: 16px;
}
.current-color {
  border-radius: 5px;
  width: 25px;
  min-width: 25px;
  min-height: 25px;
  height: 25px;
}
.active-tool {
  cursor: pointer;
  color: #4287f5;
}
.upload-image {
  max-width: 22px;
}
.slider-container {
  margin-top: 20px;
}
.slider {
  -webkit-appearance: none;
  width: 100%;
  height: 5px;
  border-radius: 5px;
  background: #d3d3d3;
  outline: none;
  opacity: 0.7;
  -webkit-transition: 0.2s;
  -webkit-transition: opacity 0.2s;
  transition: opacity 0.2s;

  &:hover {
    opacity: 1;
  }
}
.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #50555c;
  cursor: pointer;
}
.slider::-moz-range-thumb {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #50555c;
  cursor: pointer;
}
.tool-icon {
  height: 20px;
  width: 20px;
  -o-object-fit: contain;
  object-fit: contain;
}
.fa-lg {
  transition: transform 0.2s ease;
  &:hover {
    cursor: pointer;
    color: #4287f5;
    transform: scale(1.2);
  }
}
.image-border {
  border: 1px solid #e3ebf6;
}
.save-hint {
  visibility: hidden;
  position: absolute;
  bottom: 0;
}
</style>
<style lang="scss">
.markup_container {
  .editor-container,
  .custom-editor {
    width: 100%;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;

    &.image-left {
      .canvas-container {
        margin-right: auto;
      }
    }
    &.image-right {
      .canvas-container {
        margin-left: auto;
      }
    }
    &.image-center {
      .canvas-container {
        margin-left: auto;
        margin-right: auto;
      }
    }

    .canvas-container {
      max-width: 100%;
      &,
      canvas {
        max-width: 100%;
      }
    }
  }
}
</style>
