<template>
  <div class="position-relative text-center edit-container m-auto">
    <input
      class="d-none"
      id="file-upload"
      type="file"
      accept="image/*"
      @change="onChange"
    />
    <label
      class="text-center p-2 custom-avatar pointer-cursor m-0 font-weight-bold"
      for="file-upload"
    >
      Edit
    </label>
  </div>
</template>

<script>
import Swal from "sweetalert2";

export default {
  methods: {
    onChange(e) {
      if (!e.target.files.length) return;

      let file = e.target.files[0];
      if (!file.type.match(/image-*/)) {
        return Swal.fire({
          title: "Error",
          html: "The uploaded file is not an image!",
          icon: "error",
        });
      }

      let reader = new FileReader();

      reader.readAsDataURL(file);

      reader.onload = (e) => {
        let src = e.target.result;
        this.$emit("loaded", { src, file });
      };
    },
  },
};
</script>
<style scoped>
.edit-container {
  width: 200px;
}
</style>
