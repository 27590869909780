<template>
  <div class="loader">
    <img class="loader-image" src="@/assets/img/loader.gif" />
    <slot></slot>
  </div>
</template>
<script>
export default {
  name: "loader",
};
</script>
<style scoped>
.loader {
  position: fixed;
  z-index: 99;
  background: rgba(255, 255, 255, 0.3);
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.loader-image {
  width: 100px;
  height: 100px;
}
</style>
