<template>
  <div class="card">
    <div class="card-body">
      <div class="dropzone dropzone-multiple" ref="dropzone">
        <!-- Fallback -->
        <div class="fallback">
          <div class="custom-file">
            <input
              type="file"
              class="custom-file-input"
              id="customFileUploadMultiple"
              multiple
            />
            <label class="custom-file-label" for="customFileUploadMultiple"
              >Failed uploads</label
            >
          </div>
        </div>

        <!-- Preview -->
        <ul
          class="
            dz-preview dz-preview-multiple
            list-group list-group-lg list-group-flush
          "
        >
          <li class="list-group-item">
            <div class="row align-items-center">
              <div class="col">
                <!-- Heading -->
                <h4 class="mb-1" data-dz-name>...</h4>

                <!-- Text -->
                <small class="text-muted" data-dz-size>...</small>

                <div class="progress progress-sm">
                  <div
                    class="progress-bar"
                    role="progressbar"
                    data-dz-uploadprogress
                    style="width: 0%"
                    aria-valuenow="10"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
              </div>
            </div>
          </li>
        </ul>
        <span v-if="status" class="pt-3 m-0 px-0 text-muted"
          >{{ status }}
          <icon
            v-if="status == 'Processing...'"
            type="circle-notch"
            class="fa-spin"
        /></span>
      </div>
      <div v-if="showFailedFiles" class="mt-3">
        <h5>Failed Uploaded Files</h5>
        <ul class="mb-0">
          <li v-for="file in failedFiles" :key="file.name">{{ file.name }}</li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import Dropzone from "dropzone";
import Swal from "sweetalert2";
import { mapState } from "vuex";
export default {
  computed: {
    ...mapState({
      baseUrl: (state) => state.appConfig.api,
    }),
  },
  data() {
    return {
      status: null,
      dropzoneComponent: null,
      showFailedFiles: false,
      failedFiles: [],
    };
  },
  props: {
    options: {
      type: Object,
      required: true,
    },
  },
  watch: {
    options: function () {
      this.dropzoneComponent.URL = this.baseUrl + this.options.url;
      this.dropzoneComponent.options.url = this.baseUrl + this.options.url;
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    init: function () {
      let vm = this;
      let uploadedFiles = 0;
      let failFiles = 0;
      let totalFiles = 0;
      Dropzone.autoDiscover = false;
      Dropzone.thumbnailWidth = null;
      Dropzone.thumbnailHeight = null;
      let el = this.$refs.dropzone;
      let options = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("ARSS_TOKEN"),
        },
        ...this.options,
        maxFilesize: 99999,
        maxFiles: 20,
        url: this.baseUrl + this.options.url,
        previewsContainer: el.querySelector(".dz-preview"),
        previewTemplate: el.querySelector(".dz-preview").innerHTML,
        parallelUploads: 1,
        init: function () {
          this.on("success", function (file) {
            if (file.status == "success") {
              uploadedFiles++;
              if (file.status == "success") {
                vm.$emit("success", file);
                this.removeFile(file);
              }

              if (uploadedFiles + failFiles === totalFiles) {
                Swal.fire(
                  "Upload Complete",
                  `${uploadedFiles} ${
                    uploadedFiles === 1 ? "file" : "files"
                  } uploaded successfully.${
                    failFiles
                      ? `<br>${failFiles} file${
                          failFiles === 1 ? "" : "s"
                        } failed to upload.`
                      : ""
                  }`,
                  "success"
                );
                vm.status = null;
                uploadedFiles = 0;
                failFiles = 0;
                totalFiles = 0;
              }
            }
          });
          this.on("uploadprogress", function (_, progress) {
            if (progress == 100) {
              vm.status = "Processing...";
            }
          });
          this.on("error", function (file, errorMessage) {
            vm.$emit("error", file);
            vm.status = null;
            vm.showFailedFiles = true;
            vm.failedFiles.push({
              name: file.name,
            });
            failFiles++;
            this.removeFile(file);
            Swal.fire("Upload Error", errorMessage?.error?.message, "error");
            if (
              uploadedFiles + failFiles === totalFiles &&
              totalFiles !== failFiles
            ) {
              Swal.fire(
                "Upload Complete",
                `${uploadedFiles} ${
                  uploadedFiles === 1 ? "file" : "files"
                } uploaded successfully.${
                  failFiles
                    ? `${failFiles} file${
                        failFiles === 1 ? "" : "s"
                      } failed to upload`
                    : ""
                }`,
                "success"
              );
              uploadedFiles = 0;
              failFiles = 0;
              totalFiles = 0;
            }
            if (uploadedFiles + failFiles === totalFiles) {
              uploadedFiles = 0;
              failFiles = 0;
              totalFiles = 0;
            }
          });
          this.on("sending", function () {
            vm.status = "Uploading...";
          });
          this.on("maxfilesreached", function () {
            Swal.fire(
              "Exceeded maximum upload limit",
              "You can upload a maximum of 20 files.",
              "error"
            );
          });
          this.on("addedfile", function () {
            if (totalFiles === 0) {
              vm.failedFiles = [];
              vm.showFailedFiles = false;
            }
            totalFiles++;
          });
        },
      };
      el.querySelector(".dz-preview").innerHTML = "";
      this.dropzoneComponent = new Dropzone(el, options);
    },
  },
};
</script>
