<template>
  <div class="bot-ui bot-ui--animate">
    <transition name="fadeUp">
      <div
        class="board"
        :class="{
          fullscreen: isFullscreen,
        }"
        v-show="botActive"
        ref="board"
      >
        <Header
          @toggle-fullscreen="onToggleFullscreen"
          @close-chat="closeChat"
        />
        <vue-advanced-chat
          ref="vac"
          height="70vh"
          :current-user-id="String(loggedInUser.id)"
          :room-id="selectedChat && selectedChat.roomId"
          :rooms="JSON.stringify(castedChats)"
          :loading-rooms="initiateChatsLoading"
          :rooms-loaded="chatsLoaded"
          :messages="JSON.stringify(castedMessages)"
          :text-messages="JSON.stringify(textMessages)"
          :messages-loaded="messagesLoaded"
          load-first-room="false"
          show-add-room="true"
          show-audio="false"
          user-tags-enabled="false"
          show-reaction-emojis="false"
          :responsive-breakpoint="3000"
          room-info-enabled="false"
          show-files="true"
          show-new-messages-divider="false"
          custom-search-room-enabled="true"
          allow-copy="true"
          :accepted-files="castedAcceptedFiles"
          :textarea-action-enabled="shouldDisplayTextareaAction"
          :styles="JSON.stringify(customChatStyle)"
          :disable-sending="messageGenerating"
          :username-options="
            JSON.stringify({ minUsers: 0, currentUser: false })
          "
          @add-room="createChatHelper"
          @fetch-more-rooms="getChatsHelper(chats.page + 1)"
          @fetch-messages="getMessagesHelper($event.detail[0])"
          @send-message="sendMessageHelper($event.detail[0])"
          @toggle-rooms-list="handleToggleChatList($event.detail[0])"
          @search-room="applySearch"
          @open-file="openFile($event.detail[0])"
        >
          <div slot="custom-action-icon">
            <i
              v-if="cancelChatRunLoading"
              class="fas fa-circle-notch text-danger"
              style="font-size: 20px; margin: 0px 7px"
            ></i>
            <i
              v-else
              class="fa fa-stop-circle text-danger cursor-pointer"
              style="font-size: 20px; margin: 0px 7px"
              @click="
                cancelChatRunHelper(selectedChat.roomId, currentChatRunId)
              "
            ></i>
          </div>
          <div slot="send-icon" v-if="messageGenerating || sendMessageLoading">
            <i
              class="fas fa-spin fa-circle-notch"
              style="font-size: 20px; margin: 0px 7px"
            ></i>
          </div>
        </vue-advanced-chat>
      </div>
    </transition>
    <div class="bot-bubble">
      <button class="bubble-btn" @click="botToggle">
        <div class="bot-bubble-img" ref="botBubbleImg">
          <img :src="bubbleImg" alt="bot-logo" />
        </div>
      </button>
    </div>
  </div>
</template>
<script>
import { register } from "@ray-solutions/vue-advanced-chat";
import Header from "./Header.vue";
import { mapActions, mapState } from "vuex";
import dayjs from "dayjs";
import _ from "lodash";
import Swal from "sweetalert2";
register();

export default {
  components: {
    Header,
  },
  data() {
    return {
      botActive: true,
      isFullscreen: false,
      initiateChatsLoading: false,
      textMessages: {
        ROOMS_EMPTY: "Click (+) icon to create a new chat",
        SEARCH: "Search for a chat",
        MESSAGES_EMPTY:
          "Hello! my name is Oliver your clinic assistant, how can I help?",
        CONVERSATION_STARTED: "",
      },
      customChatStyle: {
        general: {
          colorSpinner: "#fff",
        },
        content: {
          background: "#222222",
        },
        message: {
          colorStarted: "#fff",
          colorUsername: "#195ea2",
        },
      },
      acceptedFiles: [
        "text/x-c",
        "text/x-c++",
        ".csv",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        "text/html",
        "text/x-java",
        "application/json",
        "text/markdown",
        "application/pdf",
        "text/x-php",
        "application/vnd.openxmlformats-officedocument.presentationml.presentation",
        "text/x-python",
        "text/x-script.python",
        "text/x-ruby",
        "text/x-tex",
        "text/plain",
        "text/css",
        "image/jpeg",
        "image/jpeg",
        "text/javascript",
        "image/gif",
        "image/png",
        "application/x-tar",
        "application/typescript",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "application/xml",
        "application/zip",
      ],
      limit: 10,
      filters: {
        title: "",
      },
    };
  },
  mounted() {
    this.displayDisclaimerDialog();
    this.getChatsHelper();
  },
  destroyed() {
    if (this.selectedChat && this.currentChatRunId) {
      this.cancelChatRunHelper(this.selectedChat.roomId, this.currentChatRunId);
    }
  },
  computed: {
    ...mapState({
      companyData: (state) => state.settings.companyProfile,
      chatsLoading: (state) => state.OliverChatBot.chatHistory.loading,
      chats: (state) => state.OliverChatBot.chatHistory.pagination,
      chatMessagesLoading: (state) => state.OliverChatBot.chatMessages.loading,
      chatMessages: (state) => state.OliverChatBot.chatMessages.pagination,
      selectedChat: (state) => state.OliverChatBot.selectedChat,
      loggedInUser: (state) => state.auth.user,
      messageGenerating: (state) => state.OliverChatBot.messageGenerating,
      cancelChatRunLoading: (state) => state.OliverChatBot.cancelChatRunLoading,
      currentChatRunId: (state) => state.OliverChatBot.currentChatRunId,
      sendMessageLoading: (state) => state.OliverChatBot.sendMessageLoading,
    }),
    bubbleImg: function () {
      return (
        this.companyData.bot_logo ||
        this.companyData.logo ||
        require("@/assets/img/icons/open-ai-default.svg")
      );
    },
    chatsLoaded: function () {
      return this.chats.page == this.chats.lastPage;
    },
    messagesLoaded: function () {
      return !this.chatMessagesLoading && !this.chatMessages.nextCursor;
    },
    castedChats: function () {
      return this.chats.data.map((chat) => {
        return {
          roomId: String(chat.id),
          roomName: chat.title || "New Chat",
          title: chat.title,
          threadId: chat.thread_id,
          index: chat.created_at,
          lastMessage: null,
          users: [
            {
              _id: String(this.loggedInUser.id),
              username: this.loggedInUser.name,
              avatar: this.loggedInUser.profile_picture,
            },
            {
              _id: String(-1),
              username: "Oliver AI Assistant",
              avatar: this.bubbleImg,
            },
          ],
        };
      });
    },
    castedMessages: function () {
      return this.chatMessages.data.map((msg) => {
        return {
          _id: String(msg.id),
          indexId: msg.id,
          content: msg.content,
          senderId: String(msg.participant ? msg.participant.id : -1),
          system: msg.system ? true : false,
          username: msg.participant
            ? msg.participant.name
            : "Oliver AI Assistant",
          avatar: msg.participant ? msg.participant.avatar : this.bubbleImg,
          timestamp: dayjs.unix(msg.created_at).format("h:mm A"),
          disableActions: true,
          disableReactions: true,
          files: msg.attachments.map((attachment) => {
            const attach = {
              name: attachment.name,
              size: attachment.size,
              extension:
                attachment.type == "image_file" ? "png" : attachment.type,
              type: attachment.type == "image_file" ? "png" : attachment.type,
              audio: false,
              url: attachment.url,
            };
            if ("progress" in attachment) {
              attach.progress = attachment.progress;
            }
            return attach;
          }),
        };
      });
    },
    shouldDisplayTextareaAction: function () {
      return Boolean(this.messageGenerating && this.currentChatRunId);
    },
    castedAcceptedFiles: function () {
      return this.acceptedFiles.join(", ");
    },
  },
  methods: {
    ...mapActions({
      getChats: "OliverChatBot/getChats",
      createChat: "OliverChatBot/createChat",
      getChatMessages: "OliverChatBot/getChatMessages",
      setSelectedChat: "OliverChatBot/setSelectedChat",
      sendChatMessage: "OliverChatBot/sendChatMessage",
      storeChatMessage: "OliverChatBot/storeChatMessage",
      generateChatTitle: "OliverChatBot/generateChatTitle",
      resetChatMessages: "OliverChatBot/resetChatMessages",
      establishSSEConnection: "OliverChatBot/establishSSEConnection",
      cancelChatRun: "OliverChatBot/cancelChatRun",
      uploadFile: "OliverChatBot/uploadFile",
    }),
    getChatsHelper: function (page = 1) {
      if (page == 1) {
        this.initiateChatsLoading = true;
      }
      return this.getChats({ page, limit: this.limit, ...this.filters }).then(
        (res) => {
          if (res) {
            this.initiateChatsLoading = false;
          }
          return res;
        }
      );
    },
    createChatHelper: function () {
      this.createChat().then((res) => {
        if (res) {
          this.getChatsHelper().then((res) => {
            if (res) {
              const firstChat = res.data?.data?.[0] || null;
              if (firstChat) {
                const formattedChat = this.formatChat(firstChat);
                this.setSelectedChat(formattedChat);
              }
            }
          });
        }
      });
    },
    getMessagesHelper: function ({ room, options }) {
      if (options?.reset) {
        this.setSelectedChat(room);
      }
      this.getChatMessages({
        chatId: room.roomId,
        cursor: options?.reset ? null : this.chatMessages.nextCursor,
        limit: this.limit,
      });
    },
    sendMessageHelper: async function (details) {
      const vm = this;
      const messageContent = details.content;
      const chatId = details.roomId;
      const files = details.files || [];
      const currentChat = this.chats.data.find((chat) => {
        return chat.id == chatId;
      });
      const formattedMessage = this.formatMessage(messageContent, files);
      const message = await this.storeChatMessage(formattedMessage);
      const attachments = await this.uploadFilesHelper(files, message);
      const failed = attachments.some((attachment) => attachment == false);
      if (!failed) {
        const attachmentsToSend = attachments.map(
          (attachment) => attachment.file_id
        );
        vm.sendChatMessage({
          chatId: chatId,
          data: {
            message: messageContent,
            file_ids: attachmentsToSend.length > 0 ? attachmentsToSend : null,
          },
        }).then((res) => {
          if (res) {
            vm.establishSSEConnection(chatId).then((res) => {
              if (res) {
                if (currentChat && !currentChat?.title) {
                  vm.generateChatTitle(chatId);
                }
              }
            });
          }
        });
      }
    },
    uploadFilesHelper: function (files, message) {
      const vm = this;
      const promises = [];
      files.forEach((file, index) => {
        promises.push(
          vm.uploadFile({
            file,
            message,
            attachmentId: index,
          })
        );
      });
      return new Promise(function (resolve) {
        Promise.all(promises).then((res) => {
          return resolve(res);
        });
      });
    },
    cancelChatRunHelper: function (chatId, runId) {
      return this.cancelChatRun({
        chatId,
        data: {
          run_id: runId,
        },
      });
    },
    formatChat: function (chat) {
      return {
        roomId: String(chat.id),
        roomName: chat.title || "New Chat",
        threadId: chat.thread_id,
        index: chat.created_at,
        lastMessage: null,
        users: [
          {
            _id: String(this.loggedInUser.id),
            username: this.loggedInUser.name,
            avatar: this.loggedInUser.profile_picture,
          },
          {
            _id: String(-1),
            username: "Oliver AI Assistant",
            avatar: this.bubbleImg,
          },
        ],
      };
    },
    formatMessage: function (content, files) {
      return {
        id: this.chatMessages.data.length + 1,
        thread_id: this.selectedChat.thread_id,
        content: content,
        role: "user",
        attachments: files.map((file, index) => {
          return {
            id: index,
            url: file.localUrl,
            name: file.name,
            size: file.size,
            type: file.extension,
            progress: 0,
          };
        }),
        participant: {
          id: this.loggedInUser.id,
          name: this.loggedInUser.name,
          avatar: this.loggedInUser.profile_picture,
        },
        created_at: dayjs(),
      };
    },
    applySearch: _.debounce(function ({ detail }) {
      const searchText = detail[0].value;
      this.filters.title = searchText;
      if (
        String(searchText).trim().length > 0 ||
        String(searchText).length == 0
      ) {
        this.getChatsHelper();
      }
    }, 500),
    handleToggleChatList: function () {
      if (this.selectedChat && this.currentChatRunId) {
        this.cancelChatRunHelper(
          this.selectedChat.roomId,
          this.currentChatRunId
        );
      }
      this.resetChatMessages();
      this.setSelectedChat(null);
    },
    openFile: function ({ file }) {
      window.open(file.file.url, "_blank");
    },
    botToggle: function () {
      this.botActive = !this.botActive;
      this.$emit("bot-toggle");
    },
    onToggleFullscreen: function () {
      this.isFullscreen = !this.isFullscreen;
      this.$emit("toggle-fullscreen");
    },
    closeChat: function () {
      this.botActive = false;
      this.$emit("close-chat");
    },
    displayDisclaimerDialog: function () {
      const isDisclaimerDialogShown = window.localStorage.getItem(
        "openAI-has-seen-onBoarding"
      );
      if (isDisclaimerDialogShown) {
        return;
      }
      Swal.fire({
        icon: "",
        title: "Oliver AI",
        width: "46em",
        customClass: {
          title: "mt-4",
        },
        html: ` <div class="justify-text">
        <h2 class='text-center text-danger'>IMPORTANT NOTICE REGARDING  Clinic assistant 'OLIVER' USAGE</h2>
        <hr>
        <div>While we are thrilled to see you interacting with Oliver, our innovative AI-powered clinic assistant, it's vital that we clarify the parameters and limitations of its usage, especially concerning health-related topics.<br><br>Please be advised that Oliver is not a medical professional, nor is it equipped with capabilities to offer medical or health advice. It is a language model developed using AI, and does not have access to personalized medical databases, medical training, or the ability to interpret individual health conditions or situations. Therefore it’s use should primarily be related to administrative tasks and duties related to your profession.<br><br>As such, under no circumstances should Oliver be used for obtaining medical advice, sharing personal information, diagnosing health conditions, or deciding on treatments or health interventions. This includes questions pertaining to medications, symptoms, health conditions, medical procedures, or any other health-related topic.
<br><br>All health-related questions, concerns, or issues should be directed to the appropriate healthcare professional – such as a physician, nurse, pharmacist, or other licensed healthcare provider – who has access to your health history, can interpret your specific symptoms or conditions, and can provide personalized advice.<br><br>For healthcare providers, Oliver can serve as an excellent support tool for non-clinical tasks. This includes but not limited to; assisting with writing letters, developing clinical reports based on assessment findings, creating routines, aiding in program development, crafting presentations, generating policy and procedure, and providing language translation. However, it should not be used as a tool for diagnosing, treating, or making decisions about health conditions.<br><br>Thank you for your understanding and cooperation in using Oliver responsibly. Your health and safety are of paramount importance to us. If you have any questions or concerns about these guidelines, please reach out to us for further clarification</div>
      </div>`,
        allowOutsideClick: false,
        allowEscapeKey: false,
        confirmButtonText: "I understand",
      }).then(() => {
        localStorage.setItem("openAI-has-seen-onBoarding", true);
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./animation";
.bot-ui {
  display: flex;
  flex-direction: column;
  position: fixed;
  right: 86px;
  left: 0px;
  bottom: 60px;
  z-index: 1000;

  .board {
    display: flex;
    flex-direction: column;
    position: absolute;
    overflow: hidden;
    width: 376px;
    margin-bottom: 10px;
    border-radius: 8px;
    background-color: #fff;
    box-shadow: 0 3px 30px 0 rgba(0, 0, 0, 0.15);
    right: -66px;
    bottom: 56px;
    transition: all 0.3s ease-in-out;
    @media (max-width: 600px) {
      width: calc(95% + 66px);
    }
    &.fullscreen {
      width: calc(100vw - 3.5rem);
      max-height: 2000px;
      @media (max-width: 600px) {
        width: calc(95% + 66px);
      }
    }
  }
  .bot-bubble {
    align-self: flex-end;
    padding: 0;
    .bubble-btn {
      position: relative;
      display: block;
      padding: 0;
      outline: 0;
      border: 0;
      overflow: hidden;
      box-shadow: none;
      border-radius: 50%;
      cursor: pointer;
      box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.15);
      transition: transform ease-in-out 0.2s;
      background-color: #222222;
      width: 56px;
      height: 56px;
      .bot-bubble-img {
        width: 100%;
        height: 100%;

        img {
          object-fit: cover;
          width: 100%;
          height: 100%;
        }
      }
      &:hover {
        transform: scale(1.1);
      }
    }
  }
}
</style>
