<template>
  <footer class="footer noPrint pb-5" :class="additionalFooterClass">
    <div class="container">
      <PDFDownloaderGroup :link-names-list="pdfsList"></PDFDownloaderGroup>
      <div class="row">
        <div class="col">
          <p class="my-5 text-center small text-dark">
            Community Care Connection - All Rights Reserved - Copyright &copy;
            {{ year }}
            <br />
            <version />
          </p>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import PDFDownloaderGroup from "./PDFDownloaderGroup";
export default {
  name: "FooterView",
  components: {
    PDFDownloaderGroup,
  },
  data() {
    return {
      pdfsList: [],
      publicPath: process.env.BASE_URL,
    };
  },
  computed: {
    year: () => {
      return new Date().getFullYear();
    },
    additionalFooterClass: function () {
      return `${(this.$route.name || "").replace(/\./g, "-")}-footer`;
    },
  },
};
</script>
