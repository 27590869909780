<template>
  <header class="oliver-bot__header">
    <div class="title pointer-cursor text-white">
      <span class="messaging-span">Oliver AI Assistant</span>
    </div>
    <div class="icons text-white">
      <!-- <button
          class="btn btn-sm btn-danger chat-bot-reset-button"
          @click.prevent="resetChat"
        >
          <i class="fas fa-trash"></i>
          Clear Chat
        </button> -->

      <span
        class="text-white ml-5 mr-3 pointer-cursor"
        @click.prevent="closeChat"
      >
        <i class="fas fa-minus"></i>
      </span>
      <div>
        <i
          class="fa fa-compress"
          aria-hidden="true"
          @click="toggleFullscreen"
          v-if="isFullscreen"
        ></i>
        <!-- icon to minimize -->
        <i
          class="fa fa-expand"
          aria-hidden="true"
          @click="toggleFullscreen"
          v-else
        ></i>
      </div>
    </div>
  </header>
</template>
<script>
export default {
  data() {
    return {
      isFullscreen: false,
    };
  },
  methods: {
    toggleFullscreen() {
      this.isFullscreen = !this.isFullscreen;
      this.$emit("toggle-fullscreen", this.isFullscreen);
    },
    closeChat() {
      this.$emit("close-chat");
    },
  },
};
</script>
<style lang="scss" scoped>
header {
  display: flex !important;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #222222;
  background-color: #222222;
  .title {
    font-size: 18px;
    font-weight: 600;
    z-index: 3;

    .messaging-span {
      font-size: 17px;
      letter-spacing: 0.5px;
    }
  }
  .icons {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
    i {
      font-size: 18px;
      cursor: pointer;
    }
    z-index: 3;
  }
}
</style>
